<template>
	<div class="company-profile">
		<v-row no-gutters dense class="d-flex" style="height:inherit">
			<v-col cols="12" class="col-lg-6 pt-8 my-pr">
				<app-my-information></app-my-information>
			</v-col>
			<v-col cols="12" class="col-lg-6 pt-8 my-pl">
				<app-company-information></app-company-information>
			</v-col>
		</v-row>
	</div>
</template>

<script>
const MyInformation = () => import("./MyInformation");
const CompanyInformation = () => import("./CompanyInformation");
export default {
	components: {
		AppMyInformation: MyInformation,
		AppCompanyInformation: CompanyInformation
	}
};
</script>

<style lang="scss" scoped>
.company-profile {
	height: 100% !important;
}
</style>